
import { defineComponent, ref } from "vue";
import PostCalculationAdjustment from "@/core/types/post-calculation-adjustments/postCalculationAdjustment";

import { DropDownList as KDropDown } from "@progress/kendo-vue-dropdowns";
import { Label as KLabel } from "@progress/kendo-vue-labels";
import { NumericTextBox as KNumericInput } from "@progress/kendo-vue-inputs";
import { TextArea as KTextArea } from "@progress/kendo-vue-inputs";
import { DatePicker as KDatePicker } from "@progress/kendo-vue-dateinputs";
import { Button as KButton } from "@progress/kendo-vue-buttons";
import moment from "moment";
import axios from "axios";

declare var $: any;
type Branch = { Id: number; Name: string };

export default defineComponent({
  name: "ProductivityDashboardAdjustments",
  components: {
    KDropDown,
    KLabel,
    KNumericInput,
    KTextArea,
    KDatePicker,
    KButton,
  },
  setup() {
    const _window: any = window;
    const branches: Branch[] = [
      { Id: 0, Name: "None" },
      { Id: 1, Name: "UK" },
      { Id: 2, Name: "Germany" },
      { Id: 3, Name: "USA" },
    ];

    const addBranchSelected = ref(branches[0]);
    const deductBranchSelected = ref(branches[0]);
    const dateSelected = ref(new Date());

    const defaultAdjustment = ref<PostCalculationAdjustment>({
      deductBranchId: 1,
      addBranchId: 1,
      printsNumber: 0,
      printedOrdersNumber: 0,
      framedOrdersDispatchedNumber: 0,
      printOnlyOrdersDispatchedNumber: 0,
      date: "",
      note: "",
    });

    const currentAdjustment = ref<PostCalculationAdjustment>(
      defaultAdjustment.value,
    );

    const grid = ref(null);
    const gridId = "#postCalculationAdjustmentsGrid";

    _window.onGridEdit = (e: any) => {
      console.log(e);
      e.model.Date = moment(String(e.model.Date))
        .format("yyyy-MM-DD")
        .toString();
    };

    return {
      defaultAdjustment,
      currentAdjustment,
      addBranchSelected,
      deductBranchSelected,
      dateSelected,
      branches,
      grid,
      gridId,
    };
  },
  mounted() {
    this.initializeAdjustmentsGrid();
  },
  methods: {
    initializeAdjustmentsGrid() {

      const grid = this.grid;

      $.get(
        "/form/grid/ProductivityDashboardPostCalculationAdjustmentsPartial",
        {},
        (html: string) => {
          html = html.replace(/^[^]*?<div/i, "<div");
          html = html.replace(/\/grid\//gi, "/form/$&");
          $(grid).html(html);
        },
      );
    },
    submit(event: Event) {
      event.preventDefault();

      if (this.currentAdjustment.note === "") {
        alert("Please enter a note.");
        return;
      }

      this.currentAdjustment.date = moment(String(this.dateSelected))
        .format("yyyy-MM-DD")
        .toString();
      this.currentAdjustment.addBranchId = this.addBranchSelected.Id;
      this.currentAdjustment.deductBranchId = this.deductBranchSelected.Id;

      console.info("", this.currentAdjustment);

      axios
        .post("/form/grid/PostCalculationAdjustment", this.currentAdjustment)
        .then((response: any) => {
          this.currentAdjustment = this.defaultAdjustment;
          this.addBranchSelected = this.branches[0];
          this.deductBranchSelected = this.branches[0];
          this.dateSelected = new Date();
          $(this.gridId).data("kendoGrid").dataSource.read();
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    onDeductBranchSelected(event: any): void {
      this.deductBranchSelected = event.value;
    },
    onAddBranchSelected(event: any): void {
      this.addBranchSelected = event.value;
    },
  },
});
