
import { computed, defineComponent, ref,  Ref } from 'vue';
import moment from "moment";
import { Input as KInput } from '@progress/kendo-vue-inputs';
import { Label as KLabel } from '@progress/kendo-vue-labels';
import { DateFilter } from '@/core/types/productionteams/DateFilter';
import { Dialog as KDialog, DialogActionsBar as KDialogActionBar } from '@progress/kendo-vue-dialogs';
import { Button as KButton } from '@progress/kendo-vue-buttons';
import { Slider as KSlider, SliderLabel as KSliderLabel } from '@progress/kendo-vue-inputs';
import { TabStrip as KTabStrip, TabStripTab as KTabStripTab } from '@progress/kendo-vue-layout';
import { TimePicker as KTimePicker } from '@progress/kendo-vue-dateinputs';
import { Grid as KGrid } from '@progress/kendo-vue-grid';
import ProductionTeamGrid from '@/components/ProductionTeamGrid.vue';
import { ChangeEvent } from '@progress/kendo-vue-dropdowns/dist/npm/common/events';
import { ProductionTeamAttendance } from '@/core/types/productionteams/productionTeamAttendance';
import ProductionTeamService from '@/core/services/productionTeamService';
import { DatePicker as KDatePicker } from '@progress/kendo-vue-dateinputs';
import axios from 'axios';

declare var $: any;

export default defineComponent({
  name: 'ProductionTeams',
  components: {
    KDialog,
    KDatePicker,
    KGrid,
    KDialogActionBar,
    KTabStrip,
    ProductionTeamGrid,
    KTabStripTab,
    KLabel,
    KSliderLabel,
    KTimePicker,
    KButton,
    KSlider,
    KInput
  },
  setup() {
    const dialogSlider = ref(null);
    const toolbar = ref(null);
    const productionTeamsTabStrip = ref(null);
    const isVisibleDialog = ref(false);
    let sliderStep = ref(0.1);

    const selectedTab = ref(0);

    const endDate = ref(new Date());
    const startDate = ref(new Date(endDate.value.getFullYear(), endDate.value.getMonth(), endDate.value.getDate() - 14));

    const defaultDateFilter: Ref<DateFilter> = ref({
      startDate: new Date(Date.UTC(startDate.value.getFullYear(), startDate.value.getMonth(), startDate.value.getDate(), 0, 0, 0, 0)),
      endDate: new Date(Date.UTC(endDate.value.getFullYear(), endDate.value.getMonth(), endDate.value.getDate(), 0, 0, 0, 0))
    });

    const startDateRef = computed({
      get() {
        return startDate.value
      },
      set(value: Date) {
        startDate.value = value
      }
    })

    const endDateRef = computed( {
      get() {
        return endDate.value
      },
      set(value: Date) {
        endDate.value = value
      }
    })

    const defaultAttendance = ref<ProductionTeamAttendance>({
      Id: 0,
      ProductionTeamEmployeeId: 0,
      FullName: "Default Employee",
      Date: new Date(),
      StartTimeBeforeLunch: moment('08:00', 'HH:mm').toDate(),
      EndTimeBeforeLunch: moment('12:00', 'HH:mm').toDate(),
      StartTimeAfterLunch: moment('13:00', 'HH:mm').toDate(),
      EndTimeAfterLunch: moment('17:00', 'HH:mm').toDate(),
      TotalMinutes: 0,
      TotalMinutesSpentOnDispatch: 0,
      TotalMinutesSpentOnPrinting: 0,
      TotalMinutesSpentOnPrintingPercentage: 0
    });

    const currentAttendance = ref<ProductionTeamAttendance>({
      Id: 0,
      ProductionTeamEmployeeId: 0,
      FullName: "Default Employee",
      Date: new Date(),
      StartTimeBeforeLunch: moment('08:00', 'HH:mm').toDate(),
      EndTimeBeforeLunch: moment('12:00', 'HH:mm').toDate(),
      StartTimeAfterLunch: moment('13:00', 'HH:mm').toDate(),
      EndTimeAfterLunch: moment('17:00', 'HH:mm').toDate(),
      TotalMinutes: 0,
      TotalMinutesSpentOnDispatch: 0,
      TotalMinutesSpentOnPrinting: 0,
      TotalMinutesSpentOnPrintingPercentage: 0
    });

    const dateFilters = (date: DateFilter): any => {
      return { startDate: date.startDate.toISOString(), endDate: date.endDate.toISOString() };
    }

    const additionalData = (date: DateFilter, branchId: number): any => {
      return {
        dateFilters: dateFilters(date),
        branchId: branchId
      }
    }

    const additionalDataGB = () => {
      return additionalData(defaultDateFilter.value, 1);
    };

    const additionalDataDE = () => {
      return additionalData(defaultDateFilter.value, 2);
    };

    const additionalDataUSA = () => {
      return additionalData(defaultDateFilter.value, 3);
    };

    return {
      dialogSlider,
      sliderStep,
      toolbar,
      productionTeamsTabStrip,
      isVisibleDialog,
      currentAttendance,
      defaultAttendance,
      dateFilters,
      additionalData,
      additionalDataGB,
      additionalDataDE,
      additionalDataUSA,
      selectedTab,
      startDateRef,
      endDateRef,
    };
  },
  data() {
    return {
      steps:{
        hour: 1,
        minute: 15
      },
      tabs: [
        {
          id: 0,
          title: "GB",
          content: "GB",
          url: "/form/grid/ProductionTeamGBPartial",
          gridId: "#productionTeamsGridGB",
          branchName: "GB",
          additionalDataFunctionName: "additionalDataGB",
          additionalData: this.additionalDataGB(),
          branchId: 1,
          isGridInitialized: false
        },
        {
          id: 1,
          title: "DE",
          content: "DE",
          url: "/form/grid/ProductionTeamDEPartial",
          gridId: "#productionTeamsGridDE",
          branchName: "DE",
          additionalDataFunctionName: "additionalDataDE",
          additionalData: this.additionalDataDE(),
          branchId: 2,
          isGridInitialized: false
        },
        {
          id: 2,
          title: "USA",
          content: "USA",
          url: "/form/grid/ProductionTeamUSAPartial",
          gridId: "#productionTeamsGridUSA",
          branchName: "USA",
          additionalDataFunctionName: "additionalDataUSA",
          additionalData: this.additionalDataUSA(),
          branchId: 3,
          isGridInitialized: false
        }
      ],
      productionTeamService: null as ProductionTeamService | null
    }
  },
  created() {
    this.productionTeamService = new ProductionTeamService();
  },
  mounted() {
    this.setDefaultDateFilter();

    this.initializeOnClickForCells();
  },
  unmounted() {
    $(document).off('click', 'td[data-field="date"]');
  },
  methods: {
    onExportClick(): void {
      console.log("Export");

      axios
        .post("/form/grid/ExportProductionTeamAttendanceCsv", {
          dateFilters: this.dateFilters({startDate: this.startDateRef, endDate: this.endDateRef}),
          branchId: this.tabs[this.selectedTab].branchId
        },
        {
          responseType: 'blob',
        }
      )
      .then(response => {
        const data = response.data;
        const blob = new Blob([data], { type: 'text/csv' });

        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'ProductionTeamAttendance.csv';
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      });
    },
    updateHours(): void {
      if (this.currentAttendance.StartTimeBeforeLunch && this.currentAttendance.EndTimeBeforeLunch 
          && this.currentAttendance.StartTimeAfterLunch && this.currentAttendance.EndTimeAfterLunch) {

        this.sliderStep = 1.0 / (this.currentAttendance.TotalMinutes / 15)

        const beforeLunchStartTime = moment(this.currentAttendance.StartTimeBeforeLunch, 'HH:mm');
        const beforeLunchEndTime = moment(this.currentAttendance.EndTimeBeforeLunch, 'HH:mm');

        const beforeLunchDuration = moment.duration(beforeLunchEndTime.diff(beforeLunchStartTime));

        const afterLunchStartTime = moment(this.currentAttendance.StartTimeAfterLunch, 'HH:mm');
        const afterLunchEndTime = moment(this.currentAttendance.EndTimeAfterLunch, 'HH:mm');

        const afterLunchDuration = moment.duration(afterLunchEndTime.diff(afterLunchStartTime));

        const totalMinutes = beforeLunchDuration.asMinutes() + afterLunchDuration.asMinutes();

        const printingMinutes = totalMinutes * this.currentAttendance.TotalMinutesSpentOnPrintingPercentage;

        const dispatchMinutes = totalMinutes - printingMinutes;

        this.currentAttendance.TotalMinutes = totalMinutes;
        this.currentAttendance.TotalMinutesSpentOnDispatch = Math.round(dispatchMinutes);
        this.currentAttendance.TotalMinutesSpentOnPrinting = Math.round(printingMinutes);
      }
    },
    formatDateForDialog(model: ProductionTeamAttendance): string {
      if (model.Id === 0) {
        return moment(model.Date).format("yyyy-MM-DD");
      } else {
        return new Date(Date.UTC(model.Date.getFullYear(), model.Date.getMonth(), model.Date.getDate(), 0, 0, 0, 0)).toISOString().split("T")[0];
      }
    },
    formatMinutesToHours(minutes: number): string {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.round(minutes % 60);

      return `${hours < 10 ? "0" + hours : hours}:${remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes} hours`;
    },
    setDefaultDateFilter(): void {
      const _window: any = window;
      
      const endDate = new Date();
      const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 14);

      const defaultDateFilter: DateFilter = {
        startDate: new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)),
        endDate: new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0))
      }
      
      _window.additionalDataGB = this.additionalData(defaultDateFilter, 1);
    },
    initializeOnClickForCells(): void {
      $(document).on('click', 'td[data-field="date"]', (e: Event) => {
        e.preventDefault();

        const link = $(e.currentTarget).find(".hour-link");

        if (link.text() === "N/A") {
          alert(`${link.data('employee')} is not available at this date.`);
          return;
        }

        this.defaultAttendance.ProductionTeamEmployeeId = link.data('id');
        this.defaultAttendance.FullName = link.data('employee');
        this.defaultAttendance.Date = link.data('date');

        this.getInfoForDialog(this.defaultAttendance.Date, this.defaultAttendance.ProductionTeamEmployeeId);

        this.toggleDialog();
      });
    },
    async getInfoForDialog(date: Date, id: number): Promise<void> {
      const employeeAttendance = await this.productionTeamService?.GetEmployeeAttendanceByDate(date, id);

      this.currentAttendance = employeeAttendance ?? this.defaultAttendance;
    },
    toggleDialog(): void {
      if (!this.isVisibleDialog) {
        this.updateHours();
      }
      this.isVisibleDialog = !this.isVisibleDialog;
    },
    onSelectedTab(e: any) {
      this.tabs[this.selectedTab].isGridInitialized = false;
      this.selectedTab = e.selected;
    },
    formatDateToUTC(date: Date): string {
      return date.getFullYear() + '-' +
                  String(date.getMonth() + 1).padStart(2, '0') + '-' +
                  String(date.getDate()).padStart(2, '0') + ' ' +
                  String(date.getHours()).padStart(2, '0') + ':' +
                  String(date.getMinutes()).padStart(2, '0') + ':' +
                  String(date.getSeconds()).padStart(2, '0');
    },
    async saveWorkingTime(): Promise<void> {
      if (this.currentAttendance.TotalMinutes === 0) {
        alert("Please set dispatch/value percentage.");
        return;
      }

      if (this.currentAttendance.StartTimeBeforeLunch! >= this.currentAttendance.EndTimeBeforeLunch! ||
          this.currentAttendance.StartTimeAfterLunch! >= this.currentAttendance.EndTimeAfterLunch!) {
        alert("Start time must be before end time.");
        return;
      }

      if (this.currentAttendance.EndTimeBeforeLunch! > this.currentAttendance.StartTimeAfterLunch!) {
        alert("End time before lunch must be before start time after lunch.");
        return;
      }

      const data = {
        ...this.currentAttendance,
        StartTimeBeforeLunch: this.formatDateToUTC(this.currentAttendance.StartTimeBeforeLunch!),
        EndTimeBeforeLunch: this.formatDateToUTC(this.currentAttendance.EndTimeBeforeLunch!),
        StartTimeAfterLunch: this.formatDateToUTC(this.currentAttendance.StartTimeAfterLunch!),
        EndTimeAfterLunch: this.formatDateToUTC(this.currentAttendance.EndTimeAfterLunch!)
      }

      const attendanceId = await this.productionTeamService?.UpsertAttendance(data);

      if (attendanceId != 0) {
        this.defaultAttendance.TotalMinutes = 0;
        this.defaultAttendance.TotalMinutesSpentOnDispatch = 0;
        this.defaultAttendance.TotalMinutesSpentOnPrinting = 0;
        this.defaultAttendance.TotalMinutesSpentOnPrintingPercentage = 0;
        $(`${this.tabs[this.selectedTab].gridId}`).data('kendoGrid').dataSource.read();
        this.toggleDialog();
      }
    },
    async removeWorkingTime(): Promise<void> {
      if (this.currentAttendance.Id === 0) {
        alert("This attendance is not saved yet.");
        return;
      }

      const result = confirm("Are you sure you want to delete this attendance?");

      if (result) {
        await this.productionTeamService?.ResetAttendance(this.currentAttendance.Id);

        $(`${this.tabs[this.selectedTab].gridId}`).data('kendoGrid').dataSource.read();
        this.toggleDialog();
      }
    },
    onApplyDateRangeButton(): void {
      const startDate = new Date(this.startDateRef);
      const endDate = new Date(this.endDateRef);

      const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));

      const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0));

      const dateFilter: DateFilter = {
        startDate: utcStartDate,
        endDate: utcEndDate
      }

      this.tabs[this.selectedTab].isGridInitialized = false;
      $(`${this.tabs[this.selectedTab].gridId}`).data('kendoGrid').dataSource.transport.options.read.data = this.additionalData(dateFilter, this.tabs[this.selectedTab].branchId);
      $(`${this.tabs[this.selectedTab].gridId}`).data('kendoGrid').dataSource.read();
    }
  }
});
