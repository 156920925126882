
import { defineComponent, ref, Ref } from "vue";

import { TabStrip as KTabStrip } from "@progress/kendo-vue-layout";
import { DatePicker as KDatePicker } from "@progress/kendo-vue-dateinputs";
import { Button as KButton } from "@progress/kendo-vue-buttons";
import { Loader as KLoader } from "@progress/kendo-vue-indicators";

import SummaryTab from "./Tabs/SummaryTab.vue";
import RevenueAndCommissionTab from "./Tabs/RevenueAndCommissionTab.vue";
import ClientManagerBreakdownTab from "./Tabs/ClientManagerBreakdownTab.vue";
import ClientManagerClientsTab from "./Tabs/ClientManagerClientsTab.vue";

import {
  COMMON_TABS,
  SUMMARY_TAB,
  EXPORT_OPTIONS,
  ExportOption,
  ClientManagerType,
} from "./typesAndConstants";

import type { Filters, ClientManager } from "./typesAndConstants";
import { today, dateOnly, nextMonth, endOfMonth, nthMonth } from "./helpers";
import * as api from "./api";

export default defineComponent({
  name: "ClientManagerCommissions",
  components: {
    KTabStrip,
    KDatePicker,
    KLoader,
    KButton,
    SummaryTab,
    RevenueAndCommissionTab,
    ClientManagerBreakdownTab,
    ClientManagerClientsTab,
  },
  props: {
    clientManagerType: { type: Number, required: true },
  },
  setup(props: any) {


    const isLoading = ref<boolean>(false);
    const selectedTab = ref(0);
    const userRoles: Ref<any> = ref({});
    const fromDt = nextMonth(nthMonth(today(), -6));
    const toDt = dateOnly(endOfMonth());
    const from = ref(fromDt);
    const to = ref(toDt);
    const isDataLoading = ref(false);

    const filters: Ref<Filters> = ref({
      exportOptions: EXPORT_OPTIONS,
      selectedExportOption: EXPORT_OPTIONS[0],
      clientManagers: [] as Array<ClientManager>,
      selectedClientManager: {} as ClientManager,
      clientManagerType: props.clientManagerType,
      dateRangeFrom: fromDt,
      dateRangeTo: toDt,
    });
    const rndId = ref(Math.floor(Math.random() * 10000));
    const pageTitle =
      props.clientManagerType == ClientManagerType.SalesRep
        ? "Commissions: Sales Rep"
        : "Commissions: Account Handlers";

    return {
      isLoading,
      selectedTab,
      userRoles,
      filters,
      rndId,
      pageTitle,
      from,
      to,
      isDataLoading,
    };
  },
  computed: {
    yearAndOptionKey(): string {
      return `${this.filters.clientManagerType}-${this.filters.selectedExportOption.actionName}-${this.rndId}`;
    },
    yearAndClientManagerKey(): string {
      return `${this.filters.clientManagerType}-${
        this.filters.selectedClientManager?.Email ?? Math.random()
      }-${this.rndId}`;
    },
    tabs(): any[] {
      return this.hasAdminTabs ? [SUMMARY_TAB, ...COMMON_TABS] : COMMON_TABS;
    },

    hasAdminTabs(): boolean {
      return (
        this.userRoles &&
        this.userRoles?.roles?.some((role: string) =>
          this.$account.rolesForCommissions.includes(role),
        )
      );
    },
  },
  async mounted() {
    this.isLoading = true;

    this.userRoles = await this.$account.getUserInRoles(
      this.$account.rolesForCommissions,
    );

    const { salesReps: clientManagers } =
      await api.getRevenueAndCommissionsConfig(this.filters.clientManagerType);

    this.filters.selectedExportOption ??= this.filters.exportOptions[0];
    this.filters.clientManagers = clientManagers;
    this.filters.selectedClientManager = this.filters.clientManagers[0];
    this.filters.clientManagerType = this.clientManagerType;

    this.isLoading = false;
  },
  methods: {
    //#region Event handlers
    onSelectTab(event: { selected: number }): void {
      this.selectedTab = event.selected;
      this.rndId = Math.floor(Math.random() * 1000);
    },
    onExportOptionChanged(event: { value: ExportOption }): void {
      this.filters.selectedExportOption = event.value;
    },
    onClientManagerChanged(event: { value: ClientManager }): void {
      this.filters.selectedClientManager = event.value;
    },
    onDatechange(event: any, field: "from" | "to") {
      if (field == "from") {
        this.from = event.target.value;
      } else {
        this.to = event.target.value;
      }
    },
    onApplyDateRange(): void {
      this.filters.dateRangeFrom = this.from;
      this.filters.dateRangeTo = this.to;
    },
    toggleLoader(): void {
      this.isDataLoading = !this.isDataLoading;
    },
    //#endregion
  },
});
