
import { defineComponent, markRaw, ref } from 'vue';
import DashboardGrid from '@/components/DashboardGrid.vue';
import { TabStrip as KTabStrip, TabStripTab as KTabStripTab } from "@progress/kendo-vue-layout";
import { DatePicker as KDatePicker } from '@progress/kendo-vue-dateinputs';
import { DropDownList as KDropDown } from '@progress/kendo-vue-dropdowns';
import { Button as KButton } from '@progress/kendo-vue-buttons';
import { Label as KLabel } from '@progress/kendo-vue-labels';
import moment from 'moment';
import axios from 'axios';

declare var $: any;

export default defineComponent({
  name: 'ProductivityDashboard',
  components: {
    DashboardGrid,
    KTabStrip,
    KTabStripTab,
    KDatePicker,
    KButton,
    KLabel,
    KDropDown
  },
  setup() {
    const selectedTab = ref(0);
    const tabComponent = ref(null);
    const selectedDashboardType = ref('Daily');

    const dashboardTypeMap = new Map([
      ['Daily', 0],
      ['Monthly', 1],
      ['Total', 2]
    ]);

    const endDate = ref(new Date());
    const startDate = ref(new Date(endDate.value.getFullYear(), endDate.value.getMonth(), endDate.value.getDate() - 7));

    return {
      selectedTab,
      tabComponent,
      endDate,
      startDate,
      selectedDashboardType,
      dashboardTypeMap
    };
  },
  data() {
   return {
     tabs: [
      {
        id: 0, 
        title: 'Summary',
        contentString: './Tabs/SummaryTab.vue',
        component: null,
        componentName: 'SummaryTab',
        gridIds: [
          '#productivityDashboardOrdersPerDay',
          '#productivityDashboarManHoursPerDay',
          '#productivityDashboardOrdersPerManHour',
        ],
      },
      {
        id: 1,
        title: 'Printed',
        contentString: './Tabs/PrintedTab.vue',
        component: null,
        componentName: 'PrintedTab',
        gridIds: [
          '#productivityDashboardPrintedOrdersPerDay',
          '#productivityDashboarTotalManHoursInPrinting',
          '#productivityDashboardPrintedOrdersPerManHour'
        ],
      },
      {
        id: 2,
        title: 'Dispatched',
        contentString: './Tabs/DispatchedTab.vue',
        component: null,
        componentName: 'DispatchedTab',
        gridIds: [
          '#productivityDashboardDispatchedOrdersPerDay',
          '#productivityDashboarTotalManHoursInDispatch',
          '#productivityDashboardDispatchedOrdersPerManHour'
        ]
      }
    ]
   }
  },
  mounted() {
    const _widnow: any = window;

    _widnow.formatOrdersPerManHour = (data: any) => {
      if (data == 0) {
        return '-'
      } else {
        return data;
      }
    }

    this.onSelectedTab({ selected: 0 });
  },
  methods: {
    onApplyClick() {
      const data = {
        startDate: moment(String(this.startDate)).format("yyyy-MM-DD").toString(),
        endDate: moment(String(this.endDate)).format("yyyy-MM-DD").toString(),
        type: this.dashboardTypeMap.get(this.selectedDashboardType)
      }

      axios.post('/form/grid/SetProductivityDashboardFilters', data)
        .then((response: any) => {
          if (response.data.success) {
            this.tabs.find(tab => tab.id === this.selectedTab)?.gridIds.forEach(gridSelector => {
              const grid = $(gridSelector).data('kendoGrid');
              grid.dataSource.read();
            });
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    async onSelectedTab(e: any) {
      this.selectedTab = e.selected;
      const tab = this.tabs[e.selected];

      if (!tab.component) {
        try {
          const module = await import(`./Tabs/${tab.componentName}.vue`);
          tab.component = markRaw(module.default);
        } catch (error) {
          console.error(`Failed to load component: ${tab.componentName}`, error);
        }
      }
    }
  }
});

